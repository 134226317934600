// ** React Imports
import { useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Hook Imports
import { useAuth } from 'src/hooks/useAuth'

import { userDeviceCurrent } from 'src/@api/login'

/**
 *  Set Home URL based on User Roles
 */
export const getHomeRoute = role => {
  if (role === 'client') return '/acl'
  else if (localStorage.getItem('currentMode') === 'iOS-Cloud') return '/dashboards'
  else return '/android/classification'
}

const Home = () => {
  // ** Hooks
  const auth = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (auth.user && auth.user.role) {
      // const homeRoute = getHomeRoute(auth.user.role)

      const type = async () => {
        let { data, isNotDevice } = await userDeviceCurrent()

        window.localStorage.setItem('currentMode', data)

        if (data == 'iOS-Cloud') {
          router.replace('/dashboard')
        } else if (isNotDevice == 0) {
          router.replace('/android/classification')
        } else if (data == 'Android-Cloud') {
          router.replace('/android/dashboard')
        } else if (data == 'Android-Local') {
          router.replace('/android/local/dashboard')
        }

        // window.localStorage.setItem('currentMode', data)
        // console.log(localStorage.getItem('currentMode'), 123)
        // setTimeout(() => {
        //   router.replace(homeRoute)
        // }, 1500)
      }

      type()

      // Redirect user to Home URL
      // if (await type()) {
      //   router.replace(homeRoute)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner sx={{ height: '100%' }} />
}

export default Home
